import { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import Spinner from "shared/components/spinner"
import spinnerConfig from "../utils/spinner_config"

import { GetMoreSection } from "./get_more_section"
import { fetchSections } from "../reducers/get_more_results"
import { connectionShape, serviceShape, partialStoryShape } from "app/lib/shared_prop_types"
import breakpoints from "foundation/_responsive_export.scss?variables"

import upsellPlugHOC from "./upsell_plug_hoc"

export class GetMoreView extends Component {
  constructor(props) {
    super(props)
    props.fetchResults()
    this.state = {
      ctaWidth: 360,
    }
  }

  loadMoreResults = () => {
    if (this.props.offset < this.props.limit && !this.props.loading && this.props.belowFoldLoaded) {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight * 0.65) {
        this.props.fetchResults()
      }
    }
  }

  setCtaWidth = () => {
    setTimeout(() => {
      const cardWidth = document.getElementsByClassName("explore-tile")[0]?.clientWidth + 24
      const getMoreContainerWidth = document.getElementsByClassName("get-more")[0]?.clientWidth
      const numOfColumns = Math.floor(getMoreContainerWidth / cardWidth)
      const newCtaWidth = numOfColumns * cardWidth - 24

      this.setState({ ctaWidth: newCtaWidth })
    }, 100)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.loadMoreResults, { passive: true })
    window.addEventListener("resize", this.setCtaWidth)

    this.setCtaWidth()
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.loadMoreResults, {
      passive: true,
    })
    window.removeEventListener("resize", this.setCtaWidth)
  }

  render() {
    const widthMatcher = window.matchMedia?.(`(max-width: ${breakpoints.screenXsMax})`)
    const isMobile = widthMatcher?.matches
    const everyOtherOddIndex = this.props.getMoreSections.map((_, idx) => idx).filter(idx => idx % 4 === 1)

    return (
      <div className={`get-more-results ${this.props.currentView}`}>
        <div className="get-more">
          {this.props.holidayPromoSubscriptionsUrl && (
            <div className="explore-tile">
              <a className="cta-your-own-applet-container holiday-promo" href={this.props.holidayPromoSubscriptionsUrl}>
                <div class="cta">
                  <div class="title">
                    <h4>A holiday gift from IFTTT</h4>
                  </div>
                  <button className="button-tertiary button-outlined">Get 24% off</button>
                </div>
              </a>
            </div>
          )}
          {this.props.getMoreSections.map((section, idx) => {
            return (
              <GetMoreSection
                key={idx}
                section={section}
                diyPath={this.props.diyPath}
                currentView={this.props.currentView}
                oddIndex={!!(idx % 2)}
                isMobile={isMobile}
                rotateCtaText={everyOtherOddIndex.includes(idx)}
              />
            )
          })}
        </div>
        {this.props.loading && <Spinner config={spinnerConfig} />}
        <a className="diy-explore-cta" style={{ width: this.state.ctaWidth }} href={this.props.diyPath}>
          <div className="text">
            <h3>Don&apos;t see what you&apos;re looking for?</h3>
            <p className="txt-body-3">Make your own Applets using any combination of triggers and actions.</p>
          </div>
          <button className={isMobile ? "button-secondary" : "button-tertiary"} href={this.props.diyPath}>
            Create an Applet
          </button>
        </a>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const offset = state.getMoreResults.offset || 0
  let getMoreSections = []

  if (state.getMoreResults.getMoreSections) {
    getMoreSections = state.getMoreResults.getMoreSections
  } else {
    const appletsOffset = offset * 8
    const servicesOffset = offset * 2
    const storiesOffset = offset * 2

    const getMoreSection = {
      applets: state.getMoreResults.applets.slice(appletsOffset, appletsOffset + 8),
      services: state.getMoreResults.services.slice(servicesOffset, servicesOffset + 2),
      stories: state.getMoreResults.stories.slice(storiesOffset, storiesOffset + 2),
      appletsOffset,
      servicesOffset,
      storiesOffset,
    }

    getMoreSections = [getMoreSection]
  }

  return {
    currentView: state.viewToggle.currentView,
    getMoreSections,
    loading: state.getMoreResults.loading,
    origin: state.viewToggle.origin,
    diyPath: state.getMoreResults.diyPath,
    belowFoldLoaded: state.getMoreResults.belowFoldLoaded,
    offset,
    limit: state.getMoreResults.limit,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchResults: () => dispatch(fetchSections()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(upsellPlugHOC(GetMoreView))

GetMoreView.propTypes = {
  currentView: PropTypes.string,
  getMoreSections: PropTypes.arrayOf(
    PropTypes.shape({
      applets: PropTypes.arrayOf(connectionShape),
      services: PropTypes.arrayOf(serviceShape),
      stories: PropTypes.arrayOf(partialStoryShape),
      servicesOffset: PropTypes.number,
      appletsOffset: PropTypes.number,
      storiesOffset: PropTypes.number,
    })
  ),
  fetchResults: PropTypes.func,
  fetchMoreResults: PropTypes.func,
  holidayPromoSubscriptionsUrl: PropTypes.string,
  offset: PropTypes.number,
  limit: PropTypes.number,
  belowFoldLoaded: PropTypes.bool,
  loading: PropTypes.bool,
  diyPath: PropTypes.string,
  origin: PropTypes.string,
  ctaWidth: PropTypes.number,
}

GetMoreView.defaultProps = {
  getMoreSections: [],
  loading: false,
  ctaWidth: 360,
}
